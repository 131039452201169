import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react/dist/iconify.js'
import { useDispatch, useSelector } from 'react-redux'
import { signInFailure, signInStart, signInSuccess } from '../redux/user/userSlice'

const SignInLayer = () => {
    const [formData, setFormData] = useState({})
    const [confirmpassword, setconfirmPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
      };
      const { loading, error: errorMessage } = useSelector((state) => state.user);
      const dispatch = useDispatch();
      const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData)
        if (!formData.email ) {
          return dispatch(signInFailure('Please fill all the fields'));
        }
        try {
          dispatch(signInStart());
          const res = await fetch('https://jtrl9k3fgh.execute-api.ap-south-1.amazonaws.com/beta/api/collection/signin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
          });
          let data = await res.json();
          let token = data.token
          data = data.rest
          // const token = await res.headers
          if(data.newUser == false){
            setconfirmPassword(true)
            setShowPassword(true)
          }else{
            setShowPassword(true)
          }
    
          if (data.success === false) {
            dispatch(signInFailure(data.message));
          }
    
          if (res.ok) {
            if(data.newUser == false){
              navigate('/')
            }
            if(data.newUser == true){
    
              if(token !== undefined){
                console.log(token, '-------token--------')
                localStorage.setItem('access_token',token)
              }
            dispatch(signInSuccess(data));
       
    
          }
            if(data.role === 'admin' && data.newUser === true && showPassword == true && formData.password !== undefined ){
            navigate('/overall');
          }else if(data.role === 'Agencies' && data.newUser === true && formData.password !== undefined ){
            navigate('/agencies/dashboard')
          }else if(data.role === "Agent" && data.newUser === true && formData.password !== undefined || null){
            navigate("/stucred_dashboard")
          }else{
            navigate("/")
          }
          }
        } catch (error) {
          dispatch(signInFailure(error.message));
        }
      };

  return (
    <section className="auth bg-base d-flex flex-wrap">
    <div className="auth-left d-lg-block d-none">
        <div className="d-flex align-items-center flex-column h-100 justify-content-center">
            <img src="assets/images/login.jpg" alt="" />
        </div>
    </div>
    <div className="auth-right py-32 px-24 d-flex flex-column justify-content-center">
        <div className="max-w-464-px mx-auto w-100">
            <div>
                <Link to="/" className="mb-40 max-w-290-px">
                </Link>
                <h4 className="mb-12">Sign In to your Stucred Account</h4>
                <p className="mb-32 text-secondary-light text-lg">
                    Welcome back! please enter your detail
                </p>
            </div>
            <form onSubmit={handleSubmit} >
                <div className="icon-field mb-16">
                    <span className="icon top-50 translate-middle-y">
                        <Icon icon="mage:email" />
                    </span>
                    <input
                        type="email"
                        className="form-control h-56-px bg-neutral-50 radius-12"
                        placeholder="Email"
                        name='email'
                        id='email'
                        onChange={handleChange}
                        
                    />
                    
                </div>
                <div className="icon-field mb-16">

                {showPassword && (
                                 <input
                                 type="password"
                                 className="form-control h-56-px bg-neutral-50 radius-12"
                                 placeholder="Password"
                                 name='password'
                                 id='password'
                                 onChange={handleChange}
                                 
                             />
                                    )}
                           </div>

      
                <div className="">
                    <div className="d-flex justify-content-between gap-2">
                        <div className="form-check style-check d-flex align-items-center">
                            <input
                                className="form-check-input border border-neutral-300"
                                type="checkbox"
                                defaultValue=""
                                id="remeber"
                                onChange={handleChange}
                            />
                                 
                            <label className="form-check-label" htmlFor="remeber">
                                Remember me{" "}
                            </label>
                        </div>
                        <Link to="/forgot-password" className="text-primary-600 fw-medium">
                            Forgot Password?
                        </Link>
                    </div>
                </div>
                <button 
                    type="submit"
                    className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
                >
                    {" "}
                    Sign In
                </button>
        
            </form>
        </div>
    </div>
</section>
  )
}

export default SignInLayer