import React from 'react'
import StucredLayout from '../masterLayout/StucredLayout'
import Breadcrumb from './Breadcrumb'
import LoanDetails from './child/LoanDetails'

const StucredLoan = () => {
  return (
    <>
    <StucredLayout>
    <Breadcrumb title="Loan Details" />

{/* TableDataLayer */}
<LoanDetails />
    </StucredLayout>
    </>
  )
}

export default StucredLoan