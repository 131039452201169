import React from 'react'
import UnitCountTwo from './child/UnitCountTwo'
import RevenueGrowthOne from './child/RevenueGrowthOne'
import EarningStaticOne from './child/EarningStaticOne'
import TopPerformanceOne from './child/TopPerformanceOne'
import DoubleChartCollect from './child/DoubleChartCollect'
import UserActivatesTwo from './child/UserActivatesTwo'


const DashBoardLayerTwo = () => {
  return (
    <section className="row gy-4">

      {/* UnitCountTwo */}
      <UnitCountTwo />
      <RevenueGrowthOne />
      <EarningStaticOne/>
      <TopPerformanceOne />
      <DoubleChartCollect/>
      <UserActivatesTwo/>




    </section>

  )
}

export default DashBoardLayerTwo