import { BrowserRouter, Route, Routes } from "react-router-dom";
import OverAlldashboard from "./Pages/OverAlldashboard";
import Inhousedashboard from "./Pages/Inhousedashboard";
import LoanMapping from "./Pages/LoanMapping";
import LoanDetail from "./Pages/LoanDetail";
import UserListPage from "./Pages/UserListPage";
import UserGridPage from './Pages/UsersGridPage'
import AddUserPage from './Pages/AddUserPage'
import ViewProfilePage from './Pages/ViewProfilePage'
import RoleAccessPage from "./Pages/RoleAccessPage";
import AssignRolePage from "./Pages/AssignRolePage";
import StucredLoan from "./components/StucredLoan";
import StucredProfile from "./Pages/StucredProfile";
import StucredDashboard from "./Pages/StucredDashboard";
import SignInPage from "./Pages/SignInPage";
import ForgotPassword from "./Pages/ForgotPassword";
import AgencyDashboard from "./Pages/AgencyDashboard";

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
      <Route path="/Inhouse" element={<OverAlldashboard />} />

        <Route path="/overall" element={<Inhousedashboard />} />
        <Route path="/Agency" element={<AgencyDashboard />} /> 
        <Route path="/loan_details" element={<LoanDetail />} /> 
        <Route path="/loan_mapping" element={<LoanMapping />} />
        <Route path="/users-list" element={<UserListPage />} />
        <Route path="/users-grid" element={<UserGridPage />} />
        <Route path="/add-user" element={<AddUserPage />} />
        <Route path="/view-profile" element={<ViewProfilePage />} />
        <Route path="/role-access" element={<RoleAccessPage/>}/> 
        <Route path="/assign-role" element={<AssignRolePage/>}/>
        <Route path="/stucred_loan" element={<StucredLoan/>}/>
        <Route path="/stucred_dashboard" element={<StucredDashboard/>}/>
        <Route path="/stucred_profile" element={<StucredProfile/>}/>
        <Route exact path="/" element={<SignInPage />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />




      </Routes>
  </BrowserRouter>
  </>
  );
}

export default App;
