import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const AgencyList = () => {
    const [fileNames, setFileNames] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const newFileNames = files.map((file) => file.name);
        setFileNames((prev) => [...prev, ...newFileNames]);
    };

    const removeFileName = (name) => {
        setFileNames((prev) => prev.filter((fileName) => fileName !== name));
    };
  return (
    <>
    <div className="card h-100 p-0 radius-12 mt-20">
        <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
            <div className="d-flex align-items-center flex-wrap gap-3">
               
                <select className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px" defaultValue="Select Number">
                    <option value="Select Number" disabled>
                        Agency List
                    </option>
                    <option value="1">Agency 1</option>
                    <option value="2">Agency 2</option>
                    <option value="3">Agency 3</option>
                    <option value="4">Agency 4</option>
                </select>
                <select className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px" defaultValue="Select Number">
                    <option value="Select Number" disabled>
                        Month
                    </option>
                    <option value="1">January</option>
                    <option value="2">Februray</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">Septemeber</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">Decemeber</option>


                </select>

                <select className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px" defaultValue="Select Number">
                    <option value="Select Number" disabled>
                        year
                    </option>
                    <option value="1">2018</option>
                    <option value="2">2019</option>
                    <option value="3">2020</option>
                    <option value="4">2021</option>
                    <option value="5">2022</option>
                    <option value="6">2023</option>
                    <option value="7">2024</option>
                


                </select>
                <button
                            type="button"
                            className="btn btn-outline-success-600 radius-8 px-20 py-11 d-flex align-items-center gap-2"
                        >
                            Search{" "}
                            <Icon
                                icon="mingcute:square-arrow-right-line"
                                className="text-xl"
                            />
                        </button>
                <label
                        htmlFor="file-upload-name"
                        className="mb-14 border border-neutral-600 fw-medium text-secondary-light px-16 py-10 radius-12 d-inline-flex align-items-center gap-1 bg-hover-neutral-200"
                    >
                        <Icon icon="solar:upload-linear" className="text-xl"></Icon>
                        Click to upload
                        <input
                            type="file"
                            className="form-control w-auto mt-24 form-control-lg"
                            id="file-upload-name"
                            multiple
                            hidden
                            onChange={handleFileChange}
                        />
                    </label>
           
            </div>
           
                
        </div>
        <div className="card-body p-24">
            <div className="table-responsive scroll-sm">
                <table className="table bordered-table sm-table mb-0">
                    <thead>
                        <tr>
                            <th scope="col">
                                <div className="d-flex align-items-center gap-10">
                                    <div className="form-check style-check d-flex align-items-center">
                                        <input
                                            className="form-check-input radius-4 border input-form-dark"
                                            type="checkbox"
                                            name="checkbox"
                                            id="selectAll"
                                        />
                                    </div>
                                    S.L
                                </div>
                            </th>
                            <th scope="col">Agency</th>
                            <th scope="col">Count cases </th>
                            <th scope="col">TOS Value</th>
                            <th scope="col">ROR</th>
                            <th scope="col">Positive conectivity</th>
                            <th scope="col">Number of Attemt</th>
                       


                            
                    
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center gap-10">
                                    <div className="form-check style-check d-flex align-items-center">
                                        <input
                                            className="form-check-input radius-4 border border-neutral-400"
                                            type="checkbox"
                                            name="checkbox"
                                        />
                                    </div>
                                    01
                                </div>
                            </td>
                            <td>Agency 1</td>
                            <td>1000</td>
                            <td>500</td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                        
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center gap-10">
                                    <div className="form-check style-check d-flex align-items-center">
                                        <input
                                            className="form-check-input radius-4 border border-neutral-400"
                                            type="checkbox"
                                            name="checkbox"
                                        />
                                    </div>
                                    01
                                </div>
                            </td>
                            <td>Agency 1</td>
                            <td>1000</td>
                            <td>500</td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                        
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center gap-10">
                                    <div className="form-check style-check d-flex align-items-center">
                                        <input
                                            className="form-check-input radius-4 border border-neutral-400"
                                            type="checkbox"
                                            name="checkbox"
                                        />
                                    </div>
                                    01
                                </div>
                            </td>
                            <td>Agency 1</td>
                            <td>1000</td>
                            <td>500</td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                        
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center gap-10">
                                    <div className="form-check style-check d-flex align-items-center">
                                        <input
                                            className="form-check-input radius-4 border border-neutral-400"
                                            type="checkbox"
                                            name="checkbox"
                                        />
                                    </div>
                                    01
                                </div>
                            </td>
                            <td>Agency 1</td>
                            <td>1000</td>
                            <td>500</td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                        
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center gap-10">
                                    <div className="form-check style-check d-flex align-items-center">
                                        <input
                                            className="form-check-input radius-4 border border-neutral-400"
                                            type="checkbox"
                                            name="checkbox"
                                        />
                                    </div>
                                    01
                                </div>
                            </td>
                            <td>Agency 1</td>
                            <td>1000</td>
                            <td>500</td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                        
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center gap-10">
                                    <div className="form-check style-check d-flex align-items-center">
                                        <input
                                            className="form-check-input radius-4 border border-neutral-400"
                                            type="checkbox"
                                            name="checkbox"
                                        />
                                    </div>
                                    01
                                </div>
                            </td>
                            <td>Agency 1</td>
                            <td>1000</td>
                            <td>500</td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                        
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center gap-10">
                                    <div className="form-check style-check d-flex align-items-center">
                                        <input
                                            className="form-check-input radius-4 border border-neutral-400"
                                            type="checkbox"
                                            name="checkbox"
                                        />
                                    </div>
                                    01
                                </div>
                            </td>
                            <td>Agency 1</td>
                            <td>1000</td>
                            <td>500</td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                        
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center gap-10">
                                    <div className="form-check style-check d-flex align-items-center">
                                        <input
                                            className="form-check-input radius-4 border border-neutral-400"
                                            type="checkbox"
                                            name="checkbox"
                                        />
                                    </div>
                                    01
                                </div>
                            </td>
                            <td>Agency 1</td>
                            <td>1000</td>
                            <td>500</td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                        
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center gap-10">
                                    <div className="form-check style-check d-flex align-items-center">
                                        <input
                                            className="form-check-input radius-4 border border-neutral-400"
                                            type="checkbox"
                                            name="checkbox"
                                        />
                                    </div>
                                    01
                                </div>
                            </td>
                            <td>Agency 1</td>
                            <td>1000</td>
                            <td>500</td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                        
                        </tr>
                        <tr>
                            <td>
                                <div className="d-flex align-items-center gap-10">
                                    <div className="form-check style-check d-flex align-items-center">
                                        <input
                                            className="form-check-input radius-4 border border-neutral-400"
                                            type="checkbox"
                                            name="checkbox"
                                        />
                                    </div>
                                    01
                                </div>
                            </td>
                            <td>Agency 1</td>
                            <td>1000</td>
                            <td>500</td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                            <td>
                               1000
                            </td>
                        
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mt-24">
                <span>Showing 1 to 10 of 12 entries</span>
                <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                    <li className="page-item">
                        <Link
                            className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                            to="#"
                        >
                            <Icon icon="ep:d-arrow-left" className="" />
                        </Link>
                    </li>
                    <li className="page-item">
                        <Link
                            className="page-link text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md bg-primary-600 text-white"
                            to="#"
                        >
                            1
                        </Link>
                    </li>
                    <li className="page-item">
                        <Link
                            className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px"
                            to="#"
                        >
                            2
                        </Link>
                    </li>
                    <li className="page-item">
                        <Link
                            className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                            to="#"
                        >
                            3
                        </Link>
                    </li>
                    <li className="page-item">
                        <Link
                            className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                            to="#"
                        >
                            4
                        </Link>
                    </li>
                    <li className="page-item">
                        <Link
                            className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                            to="#"
                        >
                            5
                        </Link>
                    </li>
                    <li className="page-item">
                        <Link
                            className="page-link bg-neutral-200 text-secondary-light fw-semibold radius-8 border-0 d-flex align-items-center justify-content-center h-32-px w-32-px text-md"
                            to="#"
                        >
                            {" "}
                            <Icon icon="ep:d-arrow-right" className="" />{" "}
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    {/* Modal Start */}
    <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div className="modal-dialog modal-lg modal-dialog modal-dialog-centered">
            <div className="modal-content radius-16 bg-base">
                <div className="modal-header py-16 px-24 border border-top-0 border-start-0 border-end-0">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                        Add New Role
                    </h1>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    />
                </div>
                <div className="modal-body p-24">
                    <form action="#">
                        <div className="row">
                            <div className="col-12 mb-20">
                                <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Role Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control radius-8"
                                    placeholder="Enter Role  Name"
                                />
                            </div>
                            <div className="col-12 mb-20">
                                <label
                                    htmlFor="desc"
                                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                                >
                                    Description
                                </label>
                                <textarea
                                    className="form-control"
                                    id="desc"
                                    rows={4}
                                    cols={50}
                                    placeholder="Write some text"
                                    defaultValue={""}
                                />
                            </div>
                            <div className="col-12 mb-20">
                                <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Status{" "}
                                </label>
                                <div className="d-flex align-items-center flex-wrap gap-28">
                                    <div className="form-check checked-success d-flex align-items-center gap-2">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="label"
                                            id="Personal"
                                        />
                                        <label
                                            className="form-check-label line-height-1 fw-medium text-secondary-light text-sm d-flex align-items-center gap-1"
                                            htmlFor="Personal"
                                        >
                                            <span className="w-8-px h-8-px bg-success-600 rounded-circle" />
                                            Active
                                        </label>
                                    </div>
                                    <div className="form-check checked-danger d-flex align-items-center gap-2">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="label"
                                            id="Holiday"
                                        />
                                        <label
                                            className="form-check-label line-height-1 fw-medium text-secondary-light text-sm d-flex align-items-center gap-1"
                                            htmlFor="Holiday"
                                        >
                                            <span className="w-8-px h-8-px bg-danger-600 rounded-circle" />
                                            Inactive
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                                <button
                                    type="reset"
                                    className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary border border-primary-600 text-md px-48 py-12 radius-8"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    {/* Modal End */}
</>
  )
}

export default AgencyList