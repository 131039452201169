import ReactApexChart from 'react-apexcharts'
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const DoubleChartCollect = () => {
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
        {
            label: 'Dataset 1',
            data: [12, 19, 3, 5, 2],
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
        },
        {
            label: 'Dataset 2',
            data: [7, 11, 5, 8, 3],
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
        },
    ],
};

// Chart options
const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        tooltip: {
            enabled: true,
        },
    },
    scales: {
        x: {
            stacked: false,
        },
        y: {
            beginAtZero: true,
        },
    },
};
  return (
    <div className="col-xxl-8">
    <div className="card h-100 p-0">
        <div className="card-header border-bottom bg-base py-18 px-36">

            <h6 className="text-lg fw-semibold mb-0">Loan Collected Vs Assigned</h6>
        </div>
        <div className="card-body p-24">
        <Bar data={data} options={options} />
        </div>
    </div>
</div>
  )
}

export default DoubleChartCollect