import React from 'react'
import MasterLayout from '../masterLayout/MasterLayout'
import Breadcrumb from '../components/Breadcrumb'
import LoanDetails from '../components/child/LoanDetails'

const LoanDetail = () => {
  return (
    <MasterLayout>

    {/* Breadcrumb */}
    <Breadcrumb title="Loan Details" />

    {/* TableDataLayer */}
    <LoanDetails />

  </MasterLayout>

  )
}

export default LoanDetail