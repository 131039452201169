import React from 'react'
import UnitCountOne from './child/UnitCountOne'
import AgencyList from './child/AgencyList'
import Week from './child/Week'
import TopPerformanceOne from './child/TopPerformanceOne'

const DashBoardLayerOne = () => {
  return (
    <>
    {/* UnitCountOne */}
    <UnitCountOne />
    <AgencyList/>
    <section className="row gy-2 mt-10">

    <Week/>
    <TopPerformanceOne />
</section>

</>
  )
}

export default DashBoardLayerOne