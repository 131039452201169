import React from 'react'
import MasterLayout from '../masterLayout/MasterLayout'
import Breadcrumb from '../components/Breadcrumb'
import DashBoardLayerOne from '../components/DashBoardLayerOne'

const AgencyDashboard = () => {
  return (
    <>
    {/* MasterLayout */}
    <MasterLayout>
      {/* Breadcrumb */}
      <Breadcrumb title="Agency Dashboard" />


      {/* DashBoardLayerOne */}
      <DashBoardLayerOne />

    </MasterLayout>
  </>
  )
}

export default AgencyDashboard