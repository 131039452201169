import React from 'react'
import ForgotPasswordLayer from '../components/ForgotPasswordLayer'

const ForgotPassword = () => {
  return (
    <>

    {/* ForgotPasswordLayer */}
    <ForgotPasswordLayer />

  </>
  )
}

export default ForgotPassword