import React from 'react'
import MasterLayout from '../masterLayout/MasterLayout'
import Breadcrumb from '../components/Breadcrumb'
import UsersListLayer from '../components/UsersListLayer'

const UserListPage = () => {
  return (
    <>

    {/* MasterLayout */}
    <MasterLayout>

      {/* Breadcrumb */}
      <Breadcrumb title="Users Grid" />

      {/* UsersListLayer */}
      <UsersListLayer />

    </MasterLayout>

  </>
  )
}

export default UserListPage