import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'top',
    },
    title: {
      display: true,
      text: 'Loans Trends by Month',
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Months',
      },
    },
    y: {
      title: {
        display: true,
        text: 'Loan Amount',
      },
      min: 0,
    },
  },
};
const EarningStaticOne = () => {

    const [data, setData] = useState({ data: [], categories: [] });
    const [loading, setLoading] = useState(true); // Loading state
    const [years, setYears] = useState([]);
    let [selectedOptions, setSelectedOptions] = useState('');
    let [selectedOptions1, setSelectedOptions1] = useState('');
    let [selectedOptions2, setSelectedOptions2] = useState('');
    const [chartData,setchartData] = useState({});




    const handleSelectChanges = (event) => {
      const value = event.target.value;
    
      setSelectedOptions(Number(value));

    };
    const handleSelectChanges1 = (event) => {
      const value = event.target.value;

      setSelectedOptions1(value);

    };
    const handleSelectChanges2 = (event) => {
      const value = event.target.value;

      setSelectedOptions2(value);
      console.log(selectedOptions2,'========================ddd')

    };
  
    async function fetchData() {
      if(typeof(selectedOptions) == 'string'){
        selectedOptions = new Date().getFullYear()
      }
   
      try {
        const response = await fetch(`https://jtrl9k3fgh.execute-api.ap-south-1.amazonaws.com/beta/api/collection/line_chart?year=${selectedOptions}&month=${selectedOptions1}&week=${selectedOptions2}`);
        const json_data = await response.json();
        console.log(json_data,"===========================")
     
        setchartData({
          labels: json_data.categories,
          datasets: [
            {
              label: 'Total Loan Amounts',
              data: json_data.data,
              borderColor: 'rgba(75, 192, 192, 1)',
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              tension: 0.4, // Curve of the line
            },
          ],
        });
        await setData({data: json_data.data, categories: json_data.categories});
        setLoading(false);  // Data has been loaded

      } catch (error) {
        console.error('Error fetching data:', error);

        setLoading(false);  // Data has been loaded

      }
    }
  
    useEffect(() => {
      fetchData()
      const currentYear = new Date().getFullYear();

      const yearsArray = [];
      for (let year = 2018; year <= currentYear; year++) {
        yearsArray.push(year);
      }
      setYears(yearsArray);
    }, [selectedOptions,selectedOptions1,selectedOptions2]);
  


    return (
      loading ? (
        <>loading...</>  // You can customize this part to show a spinner or loading text
      ) : (
    
    <div className="col-xxl-8">
            <div className="card h-100 radius-8 border-0">
                <div className="card-body p-24">
                    <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between">
                    <div className="d-flex align-items-center flex-wrap gap-3">

        
                        {/* <div className="d-flex align-items-center flex-wrap gap-3">
                        <select className="form-select form-select-sm w-auto bg-base border text-secondary-light" defaultValue="">
                                <option value="" disabled>
                                    Date
                                </option>
                                <option value="loan_date">Loan Date</option>
                                <option value="due_date">Due Date</option>
                                <option value="date_closed">Date Closed</option>
                            </select>
                        </div> */}
              
                        <div className="d-flex align-items-center flex-wrap gap-3">
                        <select       value={selectedOptions}
        onChange={handleSelectChanges} className="form-select form-select-sm w-auto bg-base border text-secondary-light" defaultValue="">
                                <option value="" disabled>
                                    Year
                                </option>
                                {years.map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}

                            </select>
                        </div>

                        <div className="d-flex align-items-center flex-wrap gap-3">
                        <select  value={selectedOptions1}
        onChange={handleSelectChanges1} className="form-select form-select-sm w-auto bg-base border text-secondary-light" defaultValue="">
                                <option value="" disabled>
                                    Month
                                </option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>

                            </select>
                        </div>
                        <div className="d-flex align-items-center flex-wrap gap-3">
                        <select value={selectedOptions2}
        onChange={handleSelectChanges2}  className="form-select form-select-sm w-auto bg-base border text-secondary-light" defaultValue="">
                                <option value="" disabled>
                                    Week
                                </option>
        
        <option value='week1'>
          week1
        </option>
        <option value='week2'>
          week2
        </option> 
          <option value='week3'>
          week3
        </option> 
          <option value='week4'>
          week4
        </option>
           <option value='week5'>
          week5
        </option>

                            </select>
                        </div>
                     
                        <button
                            type="button"
                            className="btn btn-outline-info-600 radius-8 p-20 w-60-px h-50-px d-flex align-items-center justify-content-center gap-2"
                        > {"  "}Export
                       
                        </button>
                  
                    </div>
                    </div>
                
                    <div id="barChart" >

                    <Line data={chartData} options={options} />
                    </div>
                </div>
            </div>
        </div>
      )
    )
}

export default EarningStaticOne