import React from 'react'
import MasterLayout from '../masterLayout/MasterLayout'
import Breadcrumb from '../components/Breadcrumb'
import DashBoardLayerTwo from '../components/DashBoardLayerTwo'

const OverAlldashboard = () => {
  return (
    <MasterLayout>

    {/* Breadcrumb */}
    <Breadcrumb title="Inhouse Dashboard" />

    {/* DashBoardLayerTwo */}
    <DashBoardLayerTwo />

  </MasterLayout>
  )
}

export default OverAlldashboard