import React from 'react'
import SignInLayer from './SignInLayer'

const SignInPage = () => {
  return (
    <div>     
       <SignInLayer />
</div>
  )
}

export default SignInPage