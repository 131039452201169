import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables.js';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';
const LoanDetails = () => {
    const [data, setData] = useState()

    async function fetchData(){
        const Respones = await fetch('https://jtrl9k3fgh.execute-api.ap-south-1.amazonaws.com/beta/api/collection/admindefaulter')
        const json_data = await Respones.json()
        setData(json_data.data)
        
        console.log(data,"===================ddd=============")
    }
    useEffect(()=>{
        fetchData()
    })

  return (
    <div className="card">
    <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
        <div className="d-flex flex-wrap align-items-center gap-3">
            <div className="d-flex align-items-center gap-2">
                <span>Show</span>
                <select id='number' className="form-select form-select-sm w-auto" defaultValue="Select Number">
                    <option value="Select Number" disabled>
                        Select Number
                    </option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                </select>
            </div>
            <div className="icon-field">
                <input
                    type="text"
                    name="#0"
                    className="form-control form-control-sm w-auto"
                    placeholder="Search"
                />
                <span className="icon">
                    <Icon icon="ion:search-outline" />
                </span>
            </div>
        </div>
        <div className="d-flex flex-wrap align-items-center gap-3">
            <select id='status' className="form-select form-select-sm w-auto" defaultValue="Select Status">
                <option value="Select Status" disabled>
                    Select Status
                </option>
                <option value="Paid">Paid</option>
                <option value="Pending">Pending</option>
            </select>
            <Link href="invoice-add.html" className="btn btn-sm btn-primary-600">
                <i className="ri-add-line" /> Create Invoice
            </Link>
        </div>
    </div>
    <div className="card-body">
    <div style={{ overflowX: 'auto', maxHeight: '750px', overflowY: 'auto' }}>
    <table className="table bordered-table mb-0">
            <thead>
                <tr>
                    <th scope="col">
                        <div className="form-check style-check d-flex align-items-center">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="checkAll"
                            />
                            <label className="form-check-label" htmlFor="checkAll">
                                Agency Name
                            </label>
                        </div>
                    </th>
                    <th scope="col">Loan Id</th>
                    <th scope="col">Loan Amount</th>
                    <th scope="col">DPD Days</th>
                    <th scope="col">Service charge</th>
                    <th scope="col">Daily Late Fee</th>
                    <th scope="col">Total Penalty charges</th>
                    <th scope="col">Total Payable Amount</th>
                    <th scope="col">start Date</th>
                    <th scope="col">Due Date</th>

                    <th scope="col">Name</th>
                    <th scope="col">Mobile Number</th>

                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
            {data?.map((data,index)=>(

<tr>
<td>
    <div className="form-check style-check d-flex align-items-center">
        <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="check1"
        />
        <label className="form-check-label" htmlFor="check1">
            {data.Agenc_name}
        </label>
    </div>
</td>
<td>
    <Link to="#" className="text-primary-600">
      {data.loan_id}
    </Link>
</td>
<td>
    <div className="d-flex align-items-center">
     
        <h6 className="text-md mb-0 fw-medium flex-grow-1">
            {data.loan_amt}
        </h6>
    </div>
</td>
<td>{data.no_of_days_past_due}</td>
<td>{data.serviceChargeFee}</td>
<td>{data.dailyLateFee}</td>
<td>{data.totalPenalty}</td>
<td>{data.totalAmountPayable}</td>
<td>{(new Date(data.start).toISOString().split('T')[0])}</td>
<td>{(new Date(data.due).toISOString().split('T')[0])}</td>
<td>{data.student.name}</td>


<td>
    {" "}
    <span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
        {data.student.mobile}
    </span>
</td>
<td>
    <Link
        to="#"
        className="w-32-px h-32-px  me-8 bg-primary-light text-primary-600 rounded-circle d-inline-flex align-items-center justify-content-center"
    >
        <Icon icon="iconamoon:eye-light" />
    </Link>
    <Link
        to="#"
        className="w-32-px h-32-px  me-8 bg-success-focus text-success-main rounded-circle d-inline-flex align-items-center justify-content-center"
    >
        <Icon icon="lucide:edit" />
    </Link>
    <Link
        to="#"
        className="w-32-px h-32-px  me-8 bg-danger-focus text-danger-main rounded-circle d-inline-flex align-items-center justify-content-center"
    >
        <Icon icon="mingcute:delete-2-line" />
    </Link>
</td>
</tr>
            ))}

              
            </tbody>
        </table>
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-24">
            <span>Showing 1 to 10 of 12 entries</span>
            <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                <li className="page-item">
                    <Link
                        className="page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px  me-8 w-32-px bg-base"
                        to="#"
                    >
                        <Icon icon="ep:d-arrow-left" className="text-xl" />
                    </Link>
                </li>
                <li className="page-item">
                    <Link
                        className="page-link bg-primary-600 text-white fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px  me-8 w-32-px"
                        to="#"
                    >
                        1
                    </Link>
                </li>
                <li className="page-item">
                    <Link
                        className="page-link bg-primary-50 text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px  me-8 w-32-px"
                        to="#"
                    >
                        2
                    </Link>
                </li>
                <li className="page-item">
                    <Link
                        className="page-link bg-primary-50 text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px  me-8 w-32-px"
                        to="#"
                    >
                        3
                    </Link>
                </li>
                <li className="page-item">
                    <Link
                        className="page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px  me-8 w-32-px bg-base"
                        to="#"
                    >
                        {" "}
                        <Icon icon="ep:d-arrow-right" className="text-xl" />{" "}
                    </Link>
                </li>
            </ul>
        </div>
    </div>
</div>
  )
}

export default LoanDetails