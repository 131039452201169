import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'


const RevenueGrowthOne = () => {
    const [month, setMonth] = useState('')
    const [loanSum,setloanSum] = useState('')
    const [totalSum,setTotalSum] = useState('')
    const [loading, setLoading] = useState(true); // Loading state
    let  createChartTwo = (chartColor, height) => {
        console.log(loanSum,month,"===========ss===============")

        let series = [
            {
                name: "Sum of loans",
                data:loanSum,
            },
        ]

        let options = {

            chart: {
                type: 'area',
                width: '100%',
                height: 162,
                sparkline: {
                    enabled: false // Remove whitespace
                },
                toolbar: {
                    show: false
                },
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 4,
                colors: [chartColor],
                lineCap: 'round'
            },
            grid: {
                show: true,
                borderColor: 'red',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                row: {
                    colors: undefined,
                    opacity: 0.5
                },
                column: {
                    colors: undefined,
                    opacity: 0.5
                },
                padding: {
                    top: -30,
                    right: 0,
                    bottom: -10,
                    left: 0
                },
            },
            fill: {
                type: 'gradient',
                colors: [chartColor], // Set the starting color (top color) here
                gradient: {
                    shade: 'light', // Gradient shading type
                    type: 'vertical',  // Gradient direction (vertical)
                    shadeIntensity: 0.5, // Intensity of the gradient shading
                    gradientToColors: [`${chartColor}00`], // Bottom gradient color (with transparency)
                    inverseColors: false, // Do not invert colors
                    opacityFrom: .6, // Starting opacity
                    opacityTo: 0.3,  // Ending opacity
                    stops: [0, 100],
                },
            },
            // Customize the circle marker color on hover
            markers: {
                colors: [chartColor],
                strokeWidth: 3,
                size: 0,
                hover: {
                    size: 10
                }
            },
            xaxis: {
                categories: ['jan','feb','march','april','may'],
                tooltip: {
                    enabled: false,
                },
                labels: {
                    formatter: function (value) {
                        return value;
                    },
                    style: {
                        fontSize: "14px"
                    }
                },
            },
            yaxis: {
                labels: {
                    show: false
                },
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        };

        return (
            <ReactApexChart options={options} series={series} type="area" height={height} />
        )

    }
    async function fetchData(){
    const Response = await fetch('https://jtrl9k3fgh.execute-api.ap-south-1.amazonaws.com/beta/api/collection/revenue_growth')
    const jsonData = await Response.json()

 
    setMonth(jsonData.monthNames)
    setloanSum(jsonData.loanSums)
    setTotalSum(jsonData.totalSum)
    setLoading(false)

    console.log(jsonData,"========================",month,"============================dddd")

}
useEffect(()=>{
    fetchData()

},[])



  return (
    loading ? (
        <>loading...</>  // You can customize this part to show a spinner or loading text
      ) : (
    <div className="col-xxl-4">
    <div className="card h-100 radius-8 border">
        <div className="card-body p-24">
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between">
                <div>
                    <h6 className="mb-2 fw-bold text-lg">Revenue Growth</h6>
                    <span className="text-sm fw-medium text-secondary-light">
                        Weekly Report
                    </span>
                </div>
                <div className="text-end">
                    <h6 className="mb-2 fw-bold text-lg">{totalSum}</h6>
                    <span className="bg-success-focus ps-12 pe-12 pt-2 pb-2 rounded-2 fw-medium text-success-main text-sm">
                        {totalSum}
                    </span>
                </div>
            </div>
            <div id="revenue-chart" className="mt-28" >
                {/* Pass the color value & height here */}
                {createChartTwo("#487fff", 162)}
            </div>
        </div>
    </div>
</div>
      )
  )
}

export default RevenueGrowthOne