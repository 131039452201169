import React from 'react'
import StucredLayout from '../masterLayout/StucredLayout'
import Breadcrumb from './Breadcrumb'
import UnitCountFour1 from './child/UnitCountFour1'
import RevenueStatisticsOne from './child/RevenueStatisticsOne'
import SalesStatisticTwo from './child/SalesStatisticTwo'

const StucredDashboardlayout = () => {
  return (
    <>
    <div className="row gy-4">

        {/* UnitCountFour */}
        <UnitCountFour1 />

        {/* RevenueStatisticsOne */}
        <RevenueStatisticsOne />


        {/* SalesStatisticTwo */}
        <SalesStatisticTwo />


    
    </div>

</>
  )
}

export default StucredDashboardlayout