import ReactApexChart from 'react-apexcharts'
import useReactApexChart from '../../hook/useReactApexChart'
const Week = () => {
    let {  columnChartSeriesTwo, columnChartOptionsTwo} = useReactApexChart()

  return (
    <div className="col-xxl-8">
    <div className="card h-100 p-0">
        <div className="card-header border-bottom bg-base py-16 px-24">
            <h6 className="text-lg fw-semibold mb-0">Year wise collection/Assigned</h6>
        </div>
        <div className="card-body p-24">
            <ReactApexChart id="columnGroupBarChart" options={columnChartOptionsTwo} series={columnChartSeriesTwo} type="bar" height={264} />
        </div>
    </div>
</div>
  )
}

export default Week