import React from 'react'
import StucredLayout from '../masterLayout/StucredLayout'
import Breadcrumb from '../components/Breadcrumb'
import ViewProfileLayer from '../components/ViewProfileLayer'

const StucredProfile = () => {
  return (
    <>
     <>

{/* MasterLayout */}
<StucredLayout>

  {/* Breadcrumb */}
  <Breadcrumb title="View Profile" />

  {/* ViewProfileLayer */}
  <ViewProfileLayer />

</StucredLayout>

</>  
    </>
  )
}

export default StucredProfile