import React from 'react'
import StucredLayout from '../masterLayout/StucredLayout'
import StucredDashboardlayout from '../components/StucredDashboardlayout'
import Breadcrumb from '../components/Breadcrumb'

const StucredDashboard = () => {
  return (
    <>
 <StucredLayout>

{/* Breadcrumb */}
<Breadcrumb title="Investment" />

{/* DashBoardLayerFive */}
<StucredDashboardlayout />



</StucredLayout>
    </>
  )
}

export default StucredDashboard